import { Modal, Button, Input, Select, Form, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

const SendProduct = observer((props) => {
  const { btnLoading, formRef, courierList, galleryReturnOrderNo, visible, onClose, onSave } = props.store;
  return (
    <Modal
      footer={[
        <Button
          key="3"
          onClick={onClose}
        >
          取消
        </Button>,
        <Button
          key="1"
          loading={btnLoading}
          onClick={onSave}
          type="primary"
        >
          确认发货
        </Button>,
      ]}
      onCancel={onClose}
      open={visible}
      title="发货"
    >
      <Form
        labelCol={{ span: 6 }}
        ref={formRef}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="退货单号"
          name="courierId"
        >
          {galleryReturnOrderNo}
        </Form.Item>
        <Form.Item
          label="物流公司"
          name="courierCode"
        >
          <Select
            options={courierList}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          label="物流单号"
          name="courierNo"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="发货说明"
          name="shipDesc"
        >
          <Input/>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default SendProduct;
