import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { LodopPrint, request } from 'egenie-common';
import QRCode from 'qrcode';
import type { ReactElement } from 'react';
import React from 'react';
import { render } from 'react-dom';
import api from '../../../../utils/api';
import { cssProps, tableHead, uniqueCodeTag } from './printConstant';
import type { IGoodsDetail, IReturnOrderDetail, IUnicode, OrderInfo, PrintType } from './types';

const {
  boxSizing,
  paddingLeft8,
  rowHeight,
  tableCell,
  label,
} = cssProps;

export class PrintStore {
  constructor() {
    this.initLodop();
  }

  public lodopPrint: LodopPrint;

  // 初始化退货单纸张大小
  private initPageSize = (): void => {
    const strPageName = '';
    this.lodopPrint.instance.PRINT_INITA(strPageName);
    this.lodopPrint.instance.SET_PRINT_PAGESIZE(1, 2100, 2970, 'a4');
  };

  // 初始化唯一码纸张大小
  private initUnicodePageSize = (): void => {
    const strPageName = '';
    this.lodopPrint.instance.PRINT_INITA(strPageName);
    this.lodopPrint.instance.SET_PRINT_PAGESIZE(1, '40mm', '80mm');
  };

  // 生成退货单信息
  private createReturnOrderInfo = (orderInfo: OrderInfo) => {
    const {
      galleryReturnOrderNo,
      cloudWmsName,
      createTime,
      shopName,
      shopAddress,
    } = orderInfo;

    return (
      <div style={{
        paddingLeft: '20px',
        ...boxSizing,
      }}
      >
        <div style={{
          marginBottom: '8px',
          fontSize: '500',
          ...boxSizing,
        }}
        >
          退货单信息
        </div>
        <table style={{
          width: '754px',
          tableLayout: 'fixed',
          wordBreak: 'break-all',
          wordWrap: 'break-word',
        }}
        >
          <tr>
            <td>
              <div style={tableCell}>
                <div style={label}>
                  退货单号：
                </div>
                <div>
                  {galleryReturnOrderNo || ''}
                </div>
              </div>
            </td>
            <td>
              <div style={tableCell}>
                <div>
                  所属网仓：
                </div>
                <div>
                  {cloudWmsName || ''}
                </div>
              </div>
            </td>
            <td>
              <div style={tableCell}>
                <div>
                  档口：
                </div>
                <div>
                  {shopName || ''}
                </div>
              </div>
            </td>
            <td>
              <div style={tableCell}>
                <div>
                  档口地区：
                </div>
                <div>
                  {shopAddress || ''}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style={tableCell}>
                <div style={label}>
                  账单创建时间：
                </div>
                <div>
                  {createTime || ''}
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  // 生成退货单明细数据
  private createGoodsList = (goodsDetailVo: IGoodsDetail[]) => {
    return (
      <div style={{
        paddingLeft: '20px',
        marginTop: '24px',
        width: '754px',
        height: '880px',
        ...boxSizing,
      }}
      >
        <div style={{
          marginBottom: '16px',
          fontSize: '500',
          ...boxSizing,
        }}
        >
          明细数据
        </div>
        <div style={{
          width: '754px',
          height: '855px',
          border: '1px solid #E2E2E5',
          ...boxSizing,
        }}
        >
          <table
            style={{
              width: '754px',
              fontSize: '10px',
              borderCollapse: 'collapse',
              ...boxSizing,
            }}
          >
            <tr style={rowHeight}>
              {tableHead.map((el) => {
                return (
                  <td
                    key={el.key}
                    style={{
                      ...el.style,
                      borderBottom: '1px solid #E2E2E5',
                    }}
                  >
                    {el.key}
                  </td>
                );
              })}
            </tr>
            {goodsDetailVo?.map((el, index) => {
              return (
                <tr
                  key={el.uniqueCode}
                  style={rowHeight}
                >
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el.index}
                  </td>
                  <td style={{
                    width: '160px',
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    <div style={{
                      ...paddingLeft8,
                      ...boxSizing,
                      width: '152px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    >
                      <img
                        src={el?.pic}
                        style={{
                          marginRight: '4px',
                          width: '32px',
                          height: '32px',
                        }}
                      />
                      {el.goodsName}
                    </div>
                  </td>
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el?.uniqueCode}

                  </td>
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el?.goodsNo}
                  </td>
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el?.goodsSkuNo}
                  </td>
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el?.spec}
                  </td>
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el?.areaName}
                  </td>
                  <td style={{
                    ...paddingLeft8,
                    ...rowHeight,
                  }}
                  >
                    {el?.num}
                  </td>
                </tr>
              );
            })}

          </table>
        </div>
      </div>
    );
  };

  // 生成退货单需要打印的内容
  private createNode = (printData: IReturnOrderDetail, currentPage: number): ReactElement => {
    const {
      cloudWmsName,
      createTime,
      galleryReturnOrderNo,
      shopAddress,
      shopName,
      goodsDetailVo,
    } = printData;
    return (
      <div style={{
        width: '794px',
        height: '1122px',
        boxSizing: 'border-box',
      }}
      >
        <h2 style={{
          textAlign: 'center',
          paddingTop: '35px',
          marginBottom: '24px',
        }}
        >
          {`退货单${currentPage ? `(${currentPage})` : ''}`}
        </h2>
        {this.createReturnOrderInfo({
          cloudWmsName,
          createTime,
          galleryReturnOrderNo,
          shopAddress,
          shopName,
        })}
        {this.createGoodsList(goodsDetailVo?.slice(currentPage * 21, ((currentPage + 1) * 21)))}
      </div>
    );
  };

  // 初始化lodop
  private initLodop = (): void => {
    try {
      this.lodopPrint = new LodopPrint();

      // @ts-ignore
      this.lodopPrint.init();
    } catch (err) {
      console.log(err);
    }
  };

  // 请求单条打印退货单数据
  private queryPrintData = async(id: string): Promise<IReturnOrderDetail> => {
    const res: BaseData<IReturnOrderDetail> = await request({ url: `${api.galleryReturnDetail}/${id}` });
    const { data } = res;
    return data;
  };

  // 点击打印退货单
  public clickPrint = async(returnOrderIds: string[]): Promise<void> => {
    message.info('打印数据请求中，请稍后');
    for (const id of returnOrderIds) {
      try {
        let printData = await this.queryPrintData(id);

        // 判断打印内容是否超过一页， 超过一页则重开一页
        if (printData) {
          printData = {
            ...printData,
            goodsDetailVo: printData?.goodsDetailVo?.map((el, index) => {
              return {
                ...el,
                index: index + 1 < 10 ? `0${index + 1}` : index + 1,
              };
            }),
          };
          let currentPage = 0;
          do {
            currentPage++;
            const printNode = this.createNode(printData, currentPage - 1);

            // 打印
            this.createRender(printNode, 'returnOrder');
          } while (printData.goodsDetailVo?.length > (currentPage * 21));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // 生成打印html并打印
  private createRender = (printNode: ReactElement, type: PrintType): void => {
    const tempNode = document.createElement('div');
    render(printNode, tempNode, () => {
      if (type === 'returnOrder') {
        this.initPageSize();
      } else {
        this.initUnicodePageSize();
      }
      this.lodopPrint.instance.ADD_PRINT_HTM(0, 0, '100%', '100%', tempNode.innerHTML);
      this.lodopPrint.instance.PRINT();
    });
  };

  // 请求要打印的唯一码
  public PrintUnicode = async(orderIds: number[] | string[]): Promise<void> => {
    message.info('打印数据请求中，请稍后');
    const res: BaseData<IUnicode[]> = await request({
      url: api.queryPrintUnicode,
      method: 'post',
      data: { ids: orderIds.join(',') },
    });
    const unicodeInfos = res?.data || [];
    for (const item of unicodeInfos) {
      const imgUrl = await QRCode.toDataURL(item.uniqueCode);
      const unicodeNode = uniqueCodeTag({
        qrCode: imgUrl,
        ...item,
      });
      this.createRender(unicodeNode, 'unicode');
    }
  };
}
