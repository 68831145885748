
import { message } from 'antd';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './index.less';

export const CopyIcon: React.FC<{ text: string; }> = (props) => {
  const { text } = props;
  return (
    <CopyToClipboard
      onCopy={() => {
        message.success('复制成功');
      }}
      text={text}
    >
      <i
        className={`icon-fz001 ${styles.iconStyle}`}
      />
    </CopyToClipboard>
  );
};
