import { EgGrid } from 'egenie-utils';
import React from 'react';

export const Log: React.FC<{ logGrid; }> = (props) => {
  const { logGrid } = props;
  return (
    <div style={{ height: '256px' }}>
      <EgGrid store={logGrid}/>
    </div>
  );
};
