import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import type { Store } from '../../store';

export default class RemarkStore {
  @observable public visible = false;

  @observable public galleryReturnOrderId = null;

  @observable public remark = '';

  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent: Store;

  @action
  public onShow = (galleryReturnOrderId: string, remark: string) => {
    this.galleryReturnOrderId = galleryReturnOrderId;
    this.remark = remark;
    this.visible = true;
    console.log('onshow');
  };

  @action
  public onCancel = () => {
    this.visible = false;
    this.galleryReturnOrderId = null;
    this.remark = '';
  };

  @action
  public onSave = async() => {
    this.visible = false;
    await request({
      url: '/api/gallery/rest/return/order/remark',
      method: 'POST',
      data: {
        galleryReturnOrderId: this.galleryReturnOrderId,
        remark: this.remark,
      },
    });
    this.parent?.programme.handleSearch();
  };

  @action
  public textAreaChanged = (text: string) => {
    this.remark = text;
  };
}
