import React from 'react';
import noData from '../../../assets/images/noData.png';
import styles from './index.less';

export const GridEmpty = () => {
  return (
    <div className={styles.emptyWrapper}>
      <img
        src={noData}
        style={{
          height: '80px',
          marginBottom: '8px',
        }}
      />
      <div style={{ color: '#000' }}>
        查询后，呈现数据！
      </div>
    </div>
  );
};

