import { CheckCircleOutlined } from '@ant-design/icons';
import { Tabs, Timeline, Descriptions, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { ICourierInfo } from '../types';
import styles from './index.less';

const labelStyle = {
  width: '90px',
  justifyContent: 'flex-end',
};

const { Item } = Descriptions;
export const Courier: React.FC<{ courierInfoVos: ICourierInfo; }> = observer((props) => {
  const { courierName, courierNo, courierDetailList, shipDate, shipDesc } = props.courierInfoVos;
  return (
    <>
      <Descriptions column={4}>
        <Item
          label="物流公司"
          labelStyle={labelStyle}
        >
          <span>
            {courierName}
          </span>
        </Item>
        <Item
          label="物流单号"
          labelStyle={labelStyle}
        >
          <span>
            {courierNo}
          </span>
        </Item>
        <Item
          label="发货时间"
          labelStyle={labelStyle}
        >
          <span>
            {shipDate}
          </span>
        </Item>
        <Item
          label="发货说明"
          labelStyle={labelStyle}
        >
          <span>
            {shipDesc}
          </span>
        </Item>
      </Descriptions>
      {
        courierDetailList && Boolean(courierDetailList.length) && (
          <div>
            <div className={styles.timeline}>
              <div className={styles.timeTitle}>
                物流信息
              </div>
              <Timeline mode="left">
                {courierDetailList?.map((item) => {
                  if (Number(item.statusCode) === 4) {
                    return (
                      <Timeline.Item
                        dot={(
                          <CheckCircleOutlined
                            style={{ fontSize: '16px' }}
                          />
                        )}
                        key={nanoid()}
                        label={item?.statusTime}
                      >
                        <p className={styles.signed}>
                          已签收
                        </p>
                        <p>
                          {item.trace}
                        </p>
                      </Timeline.Item>
                    );
                  }
                  return (
                    <Timeline.Item
                      color="gray"
                      key={nanoid()}
                      label={item?.statusTime}
                    >
                      {item.trace}
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
          </div>
        )
      }
    </>
  );
});
