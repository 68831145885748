import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import React from 'react';
import api from '../../../../utils/api';
import type { IOptions, IPickUpStation } from '../../interface';

export default class Store {
  constructor(options) {
    this.parent = options.parent;
    this.getPickUpStation();
  }

  @observable public parent;

  @observable public visible = false;

  @observable public btnLoading = false;

  @observable public galleryReturnOrderId = '';

  @observable public formRef = React.createRef<FormInstance>();

  @observable public pickUpStationList: IOptions[] = [];

  @action public onShow = (galleryReturnOrderId) => {
    this.galleryReturnOrderId = galleryReturnOrderId;
    this.visible = true;
  };

  // 获取物流公司下拉框选项
  private getPickUpStation = () => {
    request<BaseData<IPickUpStation[]>>({ url: api.getPickUpStation }).then((res) => {
      this.pickUpStationList = res.data.map((item) => ({
        label: item.pickUpStationName,
        value: item.pickUpStationId,
      }));
    });
  };

  @action public onClose = () => {
    const { resetFields } = this.formRef.current;
    resetFields();
    this.visible = false;
  };

  @action public onSave = async(): Promise<void> => {
    const { pickUpStationId } = await this.formRef.current?.validateFields();
    this.btnLoading = true;
    const pickUpStationName = this.pickUpStationList.find((v) => v.value === pickUpStationId)?.label;
    const postData = {
      pickUpStationId,
      pickUpStationName,
      galleryReturnOrderId: this.galleryReturnOrderId,
    };
    request<BaseData<string>>({
      url: api.arrivalProduct,
      method: 'post',
      data: postData,
    }).then((v) => {
      message.success(v.data || '操作成功');
      this.parent.queryShopOrderList();
      this.onClose();
    })
      .finally(() => {
        this.btnLoading = false;
      });
  };
}
