import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import React from 'react';
import api from '../../../../utils/api';

export default class Store {
  constructor(options) {
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public visible = false;

  @observable public btnLoading = false;

  @observable public galleryReturnOrderId = '';

  @observable public galleryReturnOrderNo = '';

  @observable public formRef = React.createRef<FormInstance>();

  @observable public courierList = [];

  @action public onShow = (galleryReturnOrderId, galleryReturnOrderNo) => {
    this.galleryReturnOrderId = galleryReturnOrderId;
    this.galleryReturnOrderNo = galleryReturnOrderNo;
    this.visible = true;
  };

  @action public onClose = () => {
    const { resetFields } = this.formRef.current;
    resetFields();
    this.visible = false;
  };

  @action public onSave = (): void => {
    this.btnLoading = true;
    const { getFieldsValue } = this.formRef.current;
    const values = getFieldsValue();
    const courierName = values.courierCode ? this.courierList.find((v) => v.value === values.courierCode)?.label : undefined;
    const courierCode = values.courierCode ? this.courierList.find((v) => v.value === values.courierCode)?.courierNo : undefined;
    const postData = {
      courierName,
      ...values,
      courierCode,
      galleryReturnOrderId: this.galleryReturnOrderId,
    };
    request<BaseData<string>>({
      url: api.sendProduct,
      method: 'post',
      data: postData,
    }).then((v) => {
      message.success(v.data || '操作成功');
      this.parent.queryShopOrderList();
      this.onClose();
    })
      .finally(() => {
        this.btnLoading = false;
      });
  };
}
