import { observable, action, autorun, reaction } from 'mobx';

interface IOrderGrodProps{
  list: [];
  primaryKey: string;// 主键
  detailKey: string;// 详情key
  detailField: string;// 详情list字段
  detailHeight: number;// 每一项详情的高度
  itemHeaderColumns: EachHeaderProps[];// 每一行的标题头配置
  gridColumns: EachColumnProps[];// 表格每一行的内容;
  onPageChange: (page: number, pageSize) => void;
  itemHeaderRightContent?: (row) => React.ReactNode;// 表格每一项标题头右边内容
  loading?: boolean;// 表格loading状态
  showCheckbox?: boolean;// 是否展示多选框
  showRefresh?: boolean;
}

export interface EachColumnProps{
  width: number;// 表格每一项宽度 百分比
  name: string;// 表格列名称
  merge: boolean;// 该行是否合并
  detailFormatter: (detail, row) => React.ReactNode;// detail formatter
}

export interface EachHeaderProps{
  key: string;
  width: string;
  rowFormatter: (row) => React.ReactNode;// row formatter
}

/**
   * 通过数字计算百分数
   * @param num 数字
   * eg:56.21 56.21%
   */
function mapPercent(num: number): string {
  return `${(num * 100).toFixed(2)}%`;
}

export class OrderGridStore {
  constructor(options: IOrderGrodProps) {
    Object.assign(this, options);

    // 计算内容百分比
    autorun(() => {
      let noMergeWidth = 0; let mergeWidth = 0;
      this.gridColumns.forEach((item) => {
        if (item.merge) {
          mergeWidth += item.width || 150;
        } else {
          noMergeWidth += item.width || 150;
        }
      });

      this.mergeWrapperWidth = mergeWidth;

      // 合并列给固宽 非合并列占据剩余宽度
      this.gridColumns = this.gridColumns.map((item) => {
        return {
          ...item,
          contentWidth: item.merge ? `${item.width}px` : mapPercent(item.width / noMergeWidth),
        };
      });
    });

    // list变化重置选中项
    reaction(() => this.list, (data) => {
      this.initSelectedIds();
    });
  }

  public detailWrapperWidth = 0;

  public mergeWrapperWidth = 0;

  @observable public list = [];// 列表list

  @observable public selectedIds: React.Key[] = [];// 选中的id

  @observable public loading = false;// 表格加载状态

  public primaryKey = '';// 主键

  public detailField = '';// 详情表字段
  
  public detailKey = '';// 详情key

  public detailHeight = 0;// 每一项详情的高度

  public showCheckbox = true;// 展示多选框

  public showRefresh = false;// 是否展示刷新按钮

  @observable public allChecked = false;// 是否全选

  @observable public pageInfo = {
    page: 1,
    pageSize: 10,
    total: 0,
  };

  public itemHeaderColumns: EachHeaderProps[] = [];// 每一行的标题头

  public gridColumns: EachColumnProps[] = [];// 表格每一行的内容

  private isOnShowSizeChange = false;// 是否为pageSize改变锁

  public onPageChange: (page: number, pageSize: number) => void;

  public handlePageChange = (page: number, pageSize: number) => {
    if (this.isOnShowSizeChange) {
      this.isOnShowSizeChange = false;
      return;
    }
    this.isOnShowSizeChange = false;
    this.onPageChange && this.onPageChange(page, pageSize);
  };

  public itemHeaderRightContent: (row) => React.ReactNode;

  // 全选checkbox 勾选/反勾选
  @action public handleAllSelectChecked = (checked: boolean) => {
    if (!this.list?.length) {
      return;
    }
    this.allChecked = checked;
    this.list.forEach((item) => {
      item.checked = checked;
    });

    if (checked) {
      this.selectedIds = this.list?.map((item) => item[this.primaryKey]);
    } else {
      this.selectedIds = [];
    }
  };

  @action public handleShowSizeChange = (page, pageSize) => {
    this.isOnShowSizeChange = true;
    this.pageInfo.page = 1;
    this.pageInfo.pageSize = pageSize;
    this.onPageChange(1, pageSize);
  };

  // 每一项勾选/反勾选
  @action public handleEachItemChecked = (checked: boolean, row, index: number) => {
    this.list[index].checked = checked;
    if (checked) {
      this.selectedIds.push(row[this.primaryKey]);
    } else {
      this.selectedIds = this.selectedIds.filter((item) => item !== row[this.primaryKey]);
    }
    this.allChecked = this.selectedIds.length === this.list?.length;
  };

  // 重置选中项
  @action public initSelectedIds = () => {
    this.selectedIds = [];
    this.allChecked = false;
  };

  // 刷新列表
  public refreshTable = () => {
    this.onPageChange(this.pageInfo.page, this.pageInfo.pageSize);
  };
}
