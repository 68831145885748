import type { EgGridModel } from 'egenie-utils';
import { EgGrid } from 'egenie-utils';
import React from 'react';
import styles from './index.less';

export const GoodsInfo: React.FC<{ productGridModel: EgGridModel;totalCount: number; }> = (props) => {
  const { productGridModel, totalCount } = props;
  return (
    <div className={styles.descriptionWrap}>
      <div style={{ height: '256px' }}>
        <EgGrid store={productGridModel}/>
      </div>
      <div className={styles.calc}>
        <div className={styles.row}>
          <div className={styles.label}>
            共计：
          </div>
          <div className={styles.value}>
            {`${totalCount}件商品`}
          </div>
        </div>
      </div>
    </div>
  );
};
