import { Button, Card, Space } from 'antd';
import { ExportModal, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { OrderGrid, TopTab } from '../components';
import styles from './index.less';
import ArrivalProduct from './modal/arrivalProduct';
import { ReturnOrderDetail } from './modal/returnOrderDetail';
import SendProduct from './modal/sendProduct';
import { Store } from './store';
import EditRemark from './modal/editRemark';

const store = new Store();

const ShopOrder = observer(() => {
  const {
    programme,
    orderStatusList,
    activeStatus,
    handleTabChange,
    exportOrder,
    orderGridStore,
    exportStore,
    sendProductStore,
    arrivalProductStore,
    returnOrderDetailStore,
    printUnicode,
    remarkStore,
  } = store;
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeStatus}
        list={orderStatusList}
        onTabChange={handleTabChange}
      />
      <Card className={styles.card}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.buttonWrapper}>
        <Space>
          <Button
            onClick={() => {
              exportOrder();
            }}
            size="small"
          >
            批量导出
          </Button>
          <Button
            onClick={printUnicode}
            size="small"
          >
            打印唯一码
          </Button>
        </Space>
      </div>
      <div className={styles.orderWrapper}>
        <OrderGrid store={orderGridStore}/>
      </div>
      <ExportModal store={exportStore}/>
      <SendProduct store={sendProductStore}/>
      <ArrivalProduct store={arrivalProductStore}/>
      <ReturnOrderDetail store={returnOrderDetailStore}/>
      <EditRemark store={remarkStore}/>
    </div>
  );
});

export default ShopOrder;
