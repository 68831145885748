import React from 'react';

const boxSizing: React.CSSProperties = { boxSizing: 'border-box' };
const paddingLeft8: React.CSSProperties = { paddingLeft: '8px' };
const rowHeight: React.CSSProperties = {
  height: '39px',
  borderBottom: '1px solid #E2E2E5',
};

const tableCell: React.CSSProperties = {
  display: 'flex',
  fontSize: '10px',
  lineHeight: '26px',
};
const label: React.CSSProperties = {
  width: '76px',
  textAlign: 'right',
};

export const tableHead = [
  {
    key: '序号',
    style: {
      width: '36px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
  {
    key: '商品名称',
    style: {
      width: '150px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
  {
    key: '唯一码',
    style: {
      width: '102px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
  {
    key: '商品编码',
    style: {
      width: '102px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
  {
    key: 'SKU编码',
    style: {
      width: '120px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
  {
    key: '规格',
    style: {
      width: '60px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
  {
    key: '展厅分区',
    style: {
      width: '60px',
      ...paddingLeft8,
      ...boxSizing,
    },
    
  },
  {
    key: '退货数量',
    style: {
      width: '60px',
      ...paddingLeft8,
      ...boxSizing,
    },
  },
];

// 唯一码节点
export const uniqueCodeTag = (uniqueTag: {
  qrCode: string;
  uniqueCode: string;
  color?: string;
  size?: string;
  skuNo?: string;
  [propsName: string]: any;
}): JSX.Element => {
  const { uniqueCode, color, size, skuNo, qrCode } = uniqueTag;
  const fontSize = {
    fontSize: '10px',
    lineHeight: '12px',
    textAline: 'center',
  };
  const mb8 = { marginBottom: '8px' };
  return (
    <div style={{
      width: '40mm',
      height: '80mm',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <div style={{
        fontSize: '16px',
        textAlign: 'center',
        marginBottom: '10px',
      }}
      >
        唯一码
      </div>
      <div style={{ textAlign: 'center' }}>
        <img
          src={qrCode}
          style={{
            border: '1px solid #000',
            width: '110px',
            height: '110px',
          }}
        />
        <div style={{
          textAlign: 'center',
          paddingTop: '6px',
          fontSize: '10px',
          lineHeight: '10px',
        }}
        >
          {uniqueCode}
        </div>
      </div>
      <div style={{
        marginTop: '13px',
        padding: '0px 10px 0px 20px',
      }}
      >
        <div style={{
          ...fontSize,
          ...mb8,
        }}
        >
          {`SKU编码：${skuNo || ''}`}
        </div>
        <div style={{
          ...fontSize,
          ...mb8,
        }}
        >
          {`颜色：${color || ''}`}
        </div>
        <div style={{
          ...fontSize,
          ...mb8,
        }}
        >
          {`尺码：${size || ''}`}
        </div>
      </div>
    </div>
  );
};

export const cssProps = {
  boxSizing,
  paddingLeft8,
  rowHeight,
  tableCell,
  label,
};
