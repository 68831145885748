import { Button, message, Modal, Space } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { EgGridModel, ImgFormatter } from 'egenie-utils';
import { observable, action, computed } from 'mobx';
import moment from 'moment';
import React from 'react';
import api from '../../../../utils/api';
import type { Store } from '../../store';
import styles from './index.less';
import type { IReturnOrderDetail, OrderInfo, IGoodsDetail, ICourierInfo } from './types';

export class ReturnOrderDetailStore {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public parent: Store;

  @observable public visible = false;

  @observable public spinning = false;

  @observable public orderStatus: number;

  // 物流
  @observable public courierInfoVo: ICourierInfo = {
    courierName: '',
    courierNo: '',
    shipDate: '',
    shipDesc: '',
    courierDetailList: [],
  };

  @observable public returnOrderId: string; // 退货单id

  @observable public returnOrderInfo: OrderInfo = {
    cloudWmsName: '',
    createTime: '',
    galleryReturnOrderNo: '',
    shopAddress: '',
    shopName: '',
  };

  @observable public totalCount = 0; // 共计商品件数

  // 是否展示删除按钮
  @computed private get showDelete() {
    return this.productGridModel.rows && this.productGridModel.rows.length > 1;
  }

  // 商品明细
  public productGridModel = new EgGridModel({
    columns: [
      {
        key: 'goodsPic',
        name: '商品图片',
        formatter: ({ row }) => {
          return (
            <div className={styles.productFlex}>
              <ImgFormatter
                height={56}
                value={row.pic}
                width={56}
              />
            </div>
          );
        },
      },
      {
        key: 'goodsName',
        name: '商品名称',
        width: 200,
      },
      {
        key: 'goodsNo',
        name: '商品编码',
        width: 140,
      },
      {
        key: 'goodsSkuNo',
        name: 'SKU编码',
        width: 140,
      },
      {
        key: 'spec',
        name: '规格',
        width: 80,
      },
      {
        key: 'areaName',
        name: '展厅分区',
        width: 80,
      },
      {
        key: 'num',
        name: '退货数量',
        width: 80,
      },
      {
        key: 'pickedStatus',
        name: '拣货状态',
        width: 80,
      },
      {
        key: 'pickUpNum',
        name: '已拣货数量',
        width: 80,
      },
    ].map((el) => {
      return {
        ...el,
        resizable: true,
      };
    }),
    rows: [],
    primaryKeyField: 'goodsSkuId',
    rowHeight: 72,
    showCheckBox: false,
    showPager: false,
    showReset: false,
  });

  // 操作日志
  public logGrid = new EgGridModel({
    columns: [
      {
        key: 'operationType',
        name: '操作类型',
      },
      {
        key: 'operationResult',
        name: '操作结果',
      },
      {
        key: 'operatorShowName',
        name: '操作人',
      },
      {
        key: 'lastUpdated',
        name: '操作时间',
        formatter: ({ row }) => {
          return (
            <div>
              {moment(row.lastUpdated).format('YYYY-MM-DD HH:mm:ss')}
            </div>
          );
        },
      },
    ],
    primaryKeyField: 'id',
    rowHeight: 32,
    forceRowClick: true,
    showCheckBox: false,
    pageSize: 10,
    showSelectedTotal: false,
    showReset: false,
    showNormalEmpty: true,
    showPager: false,
  });
  
  // 请求详情
  @action public queryDetailInfo = async(): Promise<void> => {
    this.spinning = true;
    const res: BaseData<IReturnOrderDetail> = await request({ url: `${api.galleryReturnDetail }/${ this.returnOrderId}` });
    const { cloudWmsName,
      createTime,
      galleryReturnOrderNo,
      shopAddress,
      shopName,
      orderStatus,
      courierInfoVo,
      goodsDetailVo } = res.data;
    Object.assign(this, {
      returnOrderInfo: {
        cloudWmsName,
        createTime,
        galleryReturnOrderNo,
        shopAddress,
        shopName,
      },
      courierInfoVo: courierInfoVo || {
        courierName: '',
        courierNo: '',
        shipDate: '',
        shipDesc: '',
        courierDetailList: [],
      },
      orderStatus,
    });
    this.productGridModel.rows = goodsDetailVo || [];

    // 计算商品总件数
    this.totalCount = goodsDetailVo?.reduce((pre, cur) => {
      return pre + cur.num;
    }, 0);

    // 查询操作日志
    const resLog = await request<PaginationData<unknown>>({
      url: `${api.queryOrderLog}`,
      method: 'post',
      data: {
        page: 1,
        pageSize: 1000,
        id: this.returnOrderId,
        tableName: 'gallery_return_order',
      },
    });

    this.logGrid.rows = resLog.data?.list || [];
    this.logGrid.total = resLog.data?.totalCount || 0;
    this.spinning = false;
  };

  // 删除二次确认
  private handleClickDelete = (uniqueCode: string): void => {
    Modal.confirm({
      title: '确认删除该商品?',
      onOk: () => this.deleteItem(uniqueCode),
    });
  };
  
  // 删除明细
  private deleteItem = async(uniqueCode: string): Promise<void> => {
    try {
      const res = await request({
        url: api.deleteGalleryOrderItem,
        method: 'post',
        data: {
          uniqueCode,
          galleryReturnOrderId: this.returnOrderId,
        },
      });
      this.queryDetailInfo();
    } catch (error) {
      message.error('请求失败，请重试或联系管理员');
    }
  };

  // 操作已拣货
  private handlePicked = (id: number | string): void => {
    Modal.confirm({
      title: '是否已拣货?',
      onOk: () => {
        request({
          url: `${api.handlePick}${id}`,
          method: 'post',
        }).then((res: BaseData<string>) => {
          message.success(res?.info || '操作成功');
          this.queryDetailInfo();
          this.parent?.programme.handleSearch();
        });
      },
    });
  };

  @action public onShow = (returnOrderId: string) => {
    this.returnOrderId = returnOrderId;
    this.visible = true;
    this.queryDetailInfo();
    this.parent.queryShopOrderList();
  };

  @action public onCancel = (): void => {
    Object.assign(this, { visible: false });
    this.logGrid.rows = [];
    this.productGridModel.rows = [];
  };
}
