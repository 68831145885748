import { Descriptions, Row, Col, Image } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { OrderInfo } from '../types';

const { Item } = Descriptions;

const labelStyle = {
  width: '90px',
  justifyContent: 'flex-end',
};

const list = [
  {
    label: '退货单号',
    key: 'galleryReturnOrderNo',
  },
  {
    label: '所属网仓',
    key: 'cloudWmsName',
  },
  {
    label: '档口',
    key: 'shopName',
  },
  {
    label: '档口地区',
    key: 'shopAddress',
  },
  {
    label: '创建时间',
    key: 'createTime',
  },
];

export const ReturnOrderInfo: React.FC<{ returnOrderInfo: OrderInfo; }> = observer((props) => {
  const { returnOrderInfo } = props;
  return (
    <div>
      <Descriptions column={4}>
        {list?.map((el) => {
          return (
            <Item
              key={el.key}
              label={el.label}
              labelStyle={labelStyle}
              span={1}
            >
              <span>
                {returnOrderInfo[el.key]}
              </span>
            </Item>
          );
        })}
      </Descriptions>
    </div>
  );
});
