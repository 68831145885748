import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { CopyIcon } from '../components';
import type { EachColumnProps, EachHeaderProps } from '../components/orderGrid/store';
import styles from './index.less';
import type { Store } from './store';
import { Tooltip } from 'antd';

// 订单表格列信息
export const GRID_COLUMNS = (context: Store): EachColumnProps[] => {
  return [
    {
      width: 20,
      name: '商品信息',
      merge: false,
      detailFormatter: (detail, row) => {
        const {
          pic,
          goodsNo,
          goodsName,
        } = detail;
        return (
          <div className={styles.productInfo}>
            {
              pic ? (
                <ImgFormatter
                  height={56}
                  value={pic}
                  width={56}
                />
              ) : (
                <img
                  className={styles.imgStyle}
                  src={noPic}
                />
              )
            }
            <div className={styles.productText}>
              <div
                className={styles.productText}
                style={{
                  width: '95%',
                  margin: 0,
                }}
              >
                {goodsName}
              </div>
              <div>
                货号：
                {goodsNo}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      width: 8,
      name: '退货数量',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.columnFlex}>
            <div>
              {detail.num}
            </div>
          </div>
        );
      },
    },
    {
      width: 10,
      name: '所属网仓',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.columnFlex}>
            {detail.cloudWmsName}
          </div>

        );
      },
    },
    {
      width: 8,
      name: '档口',
      merge: false,
      detailFormatter: (detail, row) => {
        return (
          <div className={styles.columnFlex}>
            {detail.shopNo}
          </div>
        );
      },
    },
    {
      width: 120,
      name: '退货总数',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.columnFlex}>
            {row.totalNum}
          </div>
        );
      },
    },
    {
      width: 120,
      name: '订单状态',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.columnFlex}>
            {row.orderStatusDesc}
          </div>
        );
      },
    },
    {
      width: 150,
      name: '退款金额',
      merge: true,
      detailFormatter: (row) => {
        return (
          <div className={styles.columnFlex}>
            <div>
              应退金额：
              ¥
              {row.returnAmount || 0}
            </div>
            <div>
              供应商应退金额：
              ¥
              {row.vendorReturnAmount || 0}
            </div>
            <div>
              供应商实退金额：
              ¥
              {row.actualReturnAmount || 0}
            </div>
          </div>
        );
      },
    },
    {
      width: 150,
      name: '操作',
      merge: true,
      detailFormatter: (row) => {
        const { orderStatus } = row;
        return (
          <div className={styles.columnFlex}>
            <span
              className={styles.btnLink}
              onClick={() => {
                context.openDetailModal(row.galleryReturnOrderId);
              }}
            >
              详情
            </span>
            <span
              className={styles.btnLink}
              onClick={() => {
                context.printStore.clickPrint([row.galleryReturnOrderId]);
              }}
            >
              打印
            </span>
            {orderStatus === 1 && (
              <span
                className={styles.btnLink}
                onClick={() => {
                  context.sendProductStore.onShow(row.galleryReturnOrderId, row.galleryReturnOrderNo);
                }}
              >
                发货
              </span>
            )}
            {orderStatus === 2 && (
              <span
                className={styles.btnLink}
                onClick={() => {
                  context.arrivalProductStore.onShow(row.galleryReturnOrderId);
                }}
              >
                到货
              </span>
            )}
            {orderStatus === 1 && (
              <span
                className={styles.btnLink}
                onClick={() => {
                  context.batchInvalid(row.galleryReturnOrderId);
                }}
              >
                作废
              </span>
            )}
          </div>
        );
      },
    },
  ];
};

// 每一项的标题头
export const ITEM_HEADER_COLUMNS = (context: Store): EachHeaderProps[] => {
  return [
    {
      width: '20%',
      key: 'afterSaleNo',
      rowFormatter: (row) => {
        return (
          <>
            <div
              className={styles.platformOrderCode}
              title={row.galleryReturnOrderNo}
            >
              退货单号：
              {row.galleryReturnOrderNo}
            </div>
            <CopyIcon text={row.galleryReturnOrderNo}/>
          </>
        );
      },
    },
    {
      width: '30%',
      key: 'galleryRefundNoStr',
      rowFormatter: (row) => {
        return (
          <div className={styles.galleryRefundNo}>
            <div
              className={styles.platformOrderCode}
              title={row.galleryRefundNoStr}
            >
              平台退货单号：
              {row.galleryRefundNoStr}
            </div>
            {row.galleryRefundNoStr && <CopyIcon text={row.galleryRefundNoStr}/>}
          </div>
        );
      },
    },
    {
      width: '20%',
      key: 'creatorName',
      rowFormatter: (row) => {
        return (
          <div>
            创建人：
            {row.creatorName}
          </div>
        );
      },
    },
    {
      width: '10%',
      key: 'createTime',
      rowFormatter: (row) => {
        return (
          <div>
            创建时间：
            {row.createTime}
          </div>
        );
      },
    },
    {
      width: '10%',
      key: 'pickedStatus',
      rowFormatter: (row) => {
        if (!row.pickedStatus) {
          return null;
        }
        return (
          <div className={styles.pickedStatus}>
            {row.pickedStatus}
          </div>
        );
      },
    },
    {
      width: '10%',
      key: 'remark',
      rowFormatter: (row) => {
        const {
          remark,
          galleryReturnOrderId,
        } = row;
        return (
          <div className={styles.remarkContainer}>
            <Tooltip
              title={remark}
            >
              <div className={styles.remark}>
                备注：
                {remark || '-'}
              </div>
            </Tooltip>
            <i
              className={`icon-edit ${styles.editIcon}`}
              onClick={() => context.editRemark(galleryReturnOrderId, remark)}
            />
          </div>
        );
      },
    },
  ];
};
