import { Modal, Select, Form, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

const ArrivalProduct = observer((props) => {
  const { btnLoading, formRef, visible, onClose, onSave, pickUpStationList } = props.store;
  return (
    <Modal
      confirmLoading={btnLoading}
      destroyOnClose
      onCancel={onClose}
      onOk={onSave}
      open={visible}
      title="到货"
      width={500}
    >
      <Form
        labelCol={{ span: 6 }}
        ref={formRef}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="到货拿货站"
          name="pickUpStationId"
          rules={[
            {
              required: true,
              message: '请选择拿货站',
            },
          ]}
        >
          <Select
            options={pickUpStationList}
            placeholder="请选择"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ArrivalProduct;
