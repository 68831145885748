import { message, Modal } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { ExportStore, NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import api from '../../utils/api';
import { OrderGridStore } from '../components/orderGrid/store';
import { PrintStore } from './components/returnOrderPrint/printStore';
import { GRID_COLUMNS, ITEM_HEADER_COLUMNS } from './constant';
import type { ICourierOption, IDict, ISaleStatus, IShopList, IShopOrderList, Warehouse } from './interface';
import ArrivalProductStore from './modal/arrivalProduct/store';
import { ReturnOrderDetailStore } from './modal/returnOrderDetail/store';
import SendProductStore from './modal/sendProduct/store';
import RemarkStore from './modal/editRemark/model';

export class Store {
  constructor() {
    this.getShopList();
    this.getConfigStatus();
    this.queryShopOrderList();
    this.getCourierOptions();
    this.getCloudWarehouse();
  }

  @observable public exportStore = new ExportStore({ parent: this });

  @observable public sendProductStore = new SendProductStore({ parent: this });

  @observable public arrivalProductStore = new ArrivalProductStore({ parent: this });

  @observable public returnOrderDetailStore = new ReturnOrderDetailStore({ parent: this }); // 退货单详情

  @observable public printStore = new PrintStore();

  @observable public remarkStore = new RemarkStore({ parent: this });

  @observable public loading = false;

  @observable public orderStatusList = [];// 顶部状态

  @observable public activeStatus = -1;// 选中状态

  // 获取店铺列表
  @action private getShopList = (shopNo = ''): void => {
    request<PaginationData<IShopList>>({
      url: api.getOperationShopList,
      method: 'POST',
      data: {
        page: 1,
        pageSize: 200,
        shopNo,
      },
    })
      .then((res) => {
        this.programme.filterItems.addDict({ shopId: this.mapOptions(res.data.list, 'id', 'shopNo') });
      });
  };

  // 获取物流公司下拉框选项
  private getCourierOptions = () => {
    request<BaseData<ICourierOption[]>>({ url: api.getCourierList })
      .then((res) => {
        this.sendProductStore.courierList = res.data.map((item) => ({
          label: item.courierName,
          value: `${item.id}`,
          courierNo: item.courierNo,
        }));
      });
  };

  @action private getConfigStatus = (): void => {
    request<BaseData<ISaleStatus[]>>({ url: api.getOrderStatusList })
      .then((res) => {
        const orderStatus = this.mapOptions(res.data, 'key', 'value');
        this.orderStatusList = orderStatus;
        this.programme.filterItems.addDict({ orderStatus });
      });
  };

  // 查询店铺订单
  public queryShopOrderList = (page?): Promise<void> => {
    const params = this.programme.filterItems.params;
    if (params.time) {
      const time = (params.time as string).split(',');
      params.createBeginTime = time[0] || undefined;
      params.createEndTime = time[1] || undefined;
      delete params.time;
    }
    const pageInfo = this.orderGridStore.pageInfo;
    const orderStatus = params.orderStatus ? params.orderStatus : this.activeStatus === -1 ? undefined : this.activeStatus;
    Object.assign(params, {
      page: page || pageInfo.page,
      pageSize: pageInfo.pageSize,
      orderStatus,
    });
    this.orderGridStore.loading = true;
    return request<PaginationData>({
      url: api.getGalleryReturnOrderList,
      method: 'post',
      data: params,
    })
      .then((res) => {
        this.orderGridStore.list = res.data.list;
        this.orderGridStore.pageInfo = {
          page: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totalCount,
        };
      })
      .finally(() => {
        this.orderGridStore.loading = false;
      });
  };

  // 页码变化
  @action public onPageChange = (page: number, pageSize: number): void => {
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page,
      pageSize,
    };
    this.queryShopOrderList();
  };

  // 查询方案
  public programme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'galleryReturnOrderNo',
        label: '退货单号',
      },
      {
        type: 'select',
        field: 'orderStatus',
        label: '状态',
      },
      {
        type: 'select',
        field: 'shopId',
        label: '档口',
        showSearch: true,
        onSearchCallback: this.getShopList,
      },
      {
        type: 'input',
        field: 'goodsName',
        label: '商品名称',
      },
      {
        type: 'input',
        field: 'uniqueCode',
        label: '唯一码',
      },
      {
        type: 'dateRange',
        field: 'time',
        label: '创建时间',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'select',
        field: 'cloudWarehouseId',
        label: '所属云仓',
      },
      {
        type: 'select',
        field: 'pickedStatus',
        label: '拣货状态',
        data: [
          {
            label: '未拣货',
            value: '0',
          },
          {
            label: '部分拣货',
            value: '1',
          },
          {
            label: '拣货完成',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        field: 'tenantRange',
        label: '数据范围',
        value: '0',
        data: [
          {
            label: '测试数据',
            value: '-1',
          },
          {
            label: '非测试数据',
            value: '0',
          },
          {
            label: '全部数据',
            value: '1',
          },
        ],
      },
      {
        type: 'input',
        field: 'galleryRefundNoStr',
        label: '平台退货单号',
        isMultipleSearch: true,
      },
    ],
    count: 6,
    handleSearch: () => {
      return this.queryShopOrderList(1);
    },

  });

  // 处理顶部tab选项变化
  @action public handleTabChange = (key: number): void => {
    this.activeStatus = key;
    this.programme.filterItems.updateFilterItem([
      {
        field: 'orderStatus',
        showItem: key === -1,
      },
    ]);
    this.orderGridStore.pageInfo.page = 1;

    this.programme.handleSearch();
  };

  // 处理选项
  private mapOptions = (data, key: string, val: string): IDict[] => {
    return data?.map((item) => ({
      label: item[val],
      value: item[key],
      number: item.count,
    }));
  };

  // 获取已选数据
  @action public getSelectedRows = (): IShopOrderList[] => {
    const arr = [];
    this.orderGridStore.list.forEach((item) => {
      this.orderGridStore.selectedIds.forEach((it) => {
        if (item.saleOrderId === it) {
          arr.push(item);
        }
      });
    });
    return arr;
  };

  // 导出
  @action public exportOrder = (): void => {
    const selectedIds = this.orderGridStore.selectedIds;
    const params = this.programme.filterItems.params;
    let queryParamShow = this.programme.filterItems.translateParams.join(' ');
    if (this.activeStatus !== -1) {
      const orderStatusName = this.orderStatusList.find((el) => el.code === this.activeStatus)?.name || '';
      params.orderStatus = `${this.activeStatus}`;
      queryParamShow += ` 订单状态:${orderStatusName}`;
    }

    // TODO:filename和boss_purchase_order 要替换
    const fileName = '展厅退货单导出';
    if (selectedIds.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow(fileName, 'gallery_return_order_detail', '', {}, queryParamShow.trim(), params); // ids不传即代表导出全部数据
        },
      });
      return;
    }

    this.exportStore.onShow(fileName, 'gallery_return_order_detail', selectedIds.join(','), {}, queryParamShow.trim(), params); // 勾选部分数据
  };

  @action
  public getCloudWarehouse = async() => {
    const req = await request<BaseData<Warehouse[]>>({ url: api.findCloudWarehouseList });
    const arr = req.data?.map((item) => ({
      label: item.warehouseName,
      value: `${item.id}`,
    })) || [];

    this.programme.filterItems.addDict({ cloudWarehouseId: arr });
  };

  // 批量作废
  @action public batchInvalid = (id: number): void => {
    Modal.confirm({
      title: '提示',
      content: '是否确认作废订单？',
      onOk: () => {
        return request<BaseData>({ url: `${api.invalidOrder}/${id}` })
          .then((res) => {
            message.success(res.data || '操作成功');
            this.queryShopOrderList();
          });
      },
    });
  };

  @observable public orderGridStore = new OrderGridStore({
    list: [],
    primaryKey: 'galleryReturnOrderId',
    detailField: 'returnOrderDetailVos',
    detailHeight: 90,
    detailKey: 'goodsId',
    itemHeaderColumns: ITEM_HEADER_COLUMNS(this),
    gridColumns: GRID_COLUMNS(this),
    onPageChange: this.onPageChange,

  });

  // 打开详情弹窗
  public openDetailModal = (returnOrderId: string): void => {
    this.returnOrderDetailStore.onShow(returnOrderId);
  };

  // 打印唯一码
  public printUnicode = () => {
    const selectedIds = Array.from(this.orderGridStore.selectedIds, (el) => el.toString());
    if (selectedIds.length <= 0) {
      message.warning('请选择款式');
      return;
    }

    this.printStore.PrintUnicode(selectedIds);
  };

  /**
   * 编辑备注信息
   * @param galleryReturnOrderId
   */
  public editRemark = (galleryReturnOrderId: string, remark: string) => {
    this.remarkStore.onShow(galleryReturnOrderId, remark);
  };
}
