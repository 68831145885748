import { Spin } from 'antd';
import { FullModal } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { CollapsePanel } from '../../../components';
import { Courier } from './courier';
import { GoodsInfo } from './goodsInfo';
import styles from './index.less';
import { Log } from './Log';
import { ReturnOrderInfo } from './orderInfo';
import type { ReturnOrderDetailStore } from './store';

export const ReturnOrderDetail: React.FC<{ store: ReturnOrderDetailStore; }> = observer((props) => {
  const { visible,
    spinning,
    returnOrderInfo,
    courierInfoVo,
    productGridModel,
    logGrid,
    totalCount,
    onCancel } = props.store;

  const list = [
    {
      key: '退货单信息',
      content: <ReturnOrderInfo returnOrderInfo={returnOrderInfo}/>,
    },
    {
      key: '物流信息',
      content: <Courier courierInfoVos={courierInfoVo}/>,
    },
    {
      key: '商品明细',
      content: (
        <GoodsInfo
          productGridModel={productGridModel}
          totalCount={totalCount}
        />),
    },
    {
      key: '操作记录',
      content: (<Log logGrid={logGrid}/>),
    },
  ];
  return (
    <FullModal
      onCancel={onCancel}
      title="退货详情"
      visible={visible}
    >
      <Spin
        size="large"
        spinning={spinning}
        tip="数据加载中..."
      >
        <div className={styles.page}>
          {
            list?.map((el) => {
              return (
                <CollapsePanel
                  key={el.key}
                  title={el.key}
                >
                  {el.content}
                </CollapsePanel>
              );
            })
          }
        </div>
      </Spin>
    </FullModal>
  );
});
